<template>

<div>
   <img :src="require('../assets/images/Vencasa-Leaf-Top-Border-4K.webp')" />
   <div class="row justify-content-center">
     <div class="col-md-4 text-center">
      <img class="mb-2" style="width:55%" :src="require('../assets/images/payment.png')" />
      <h3 style="font-size:30px">Nearly there</h3>
      <p style="font-size: 23px;">We have just emailed you a confirmation code. Please enter that code below to create your account</p>
        <div class="form-group" style="text-align: -webkit-center;">
              <input @keypress="check($event)"
                
                class="form-control input-button-w"
                type="text"
                v-model="input1"
              />
               <h3 class="mt-3" v-if="!timedOut">Time Remaining: {{ timeLeft }}</h3>
              <p v-else>
            Verification time has expired please resend verification code
          </p>
            </div>
       

 <button style="background: #548f4d;"
            @click="verify()"
            :disabled="disabled"
            type="submit"
            class="btn solent-email-button"
          >
            <span>Verify Account</span>
          </button>
         <div class="mt-2 mb-5">
            <a
              style="cursor: pointer; font-weight: 600; color: #3e4452"
              @click="resendCode()"
              class="  pl-1"
            >
              Resend the Code?</a
            >
           </div>
     </div>
    </div>
  </div>

</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";

export default {
  data() {
    return {
      input1: "",
      disabled: false,
      verificationSendTime: "",
      verficationCodeLimit: "",
      timeExists: false,
      interval: null,
      timeLeft: "",
      timedOut: false,
    };
  },
  validations:{
    input1:{
      required,
    }
  },
  computed:{
    ...mapGetters({
      auth: 'auth'
    })
  },
  methods: {
    verificationCodeTime(time) {
      let time1 = null;
      let time2 = null;
      if (this.timeExists) {
        time1 = this.verficationCodeLimit.split(":")[0] ?? this.verficationCodeLimit;
        time2 = this.verficationCodeLimit.split(":")[1] ?? '00';
      }
      let sendTime = this.verificationSendTime;
      let hours = sendTime.split(":")[0];
      let minutes = sendTime.split(":")[1];
      let seconds = sendTime.split(":")[2];
      let now = moment().set({
        hour: hours,
        minutes: minutes,
        seconds: seconds,
      });
      let then = null;
      if (this.timeExists) {
        then = moment()
          .set({ hour: hours, minutes: minutes, seconds: seconds })
          .add(time1, "minutes").add( time2, "seconds")
          // .add(time2, "seconds");
      } else {
        then = moment()
          .set({ hour: hours, minutes: minutes, seconds: seconds })
          .add(this.verficationCodeLimit, "minutes");
      }
      if(this.timeExists) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => {
        now = now.add("1", "second");
        let duration = moment.duration(then.diff(now))
        this.timeLeft = duration.minutes() + ":" + (parseInt(duration.seconds()) < 10 ? `0${duration.seconds()}` : duration.seconds());
        localStorage.setItem(`verification_code_limit`, this.timeLeft);
        if (duration.minutes() == 0 && duration.seconds() == 0) {
          clearInterval(this.interval);
          this.timedOut = true;
        }
      }, 1000);
      // }
    },
    async resendCode() {
      this.$store.commit('setShowLoader', true)
      await this.$store.dispatch("resendVerifyCode").then(res => {
        this.$store.commit('setShowLoader', false)
        if(res.status == 200){
        clearInterval(this.interval)
        localStorage.setItem('verification_code_time', res.data.sendTime)
        localStorage.setItem('verification_code_limit', res.data.timeLimit)
        this.verificationSendTime = localStorage.getItem("verification_code_time") ?? '10:20:00'
        this.verficationCodeLimit = localStorage.getItem("verification_code_limit");
        this.timeExists = false
        this.timedOut = false
        this.verificationCodeTime(this.verificationSendTime);
        }
        else{
          alert("error in resending code")
        }
      });
    },
    async verify() {
      this.$v.$touch()
      if(!this.$v.$invalid){
      this.$store.commit('setShowLoader', true)
      const data = {
        code: this.input1
      };
      await this.$store.dispatch("verifyCode", data).then(res => {
        if(res == true){
          this.$store.dispatch('getUser').then(res => {
            if(res == true){
            this.$store.commit('setShowLoader', false)
              this.$router.push('/welcome')
            }
            else{
              this.$store.commit('setShowLoader', false)
              alert("error in fetching user")
            }
          })
        }
        else if(res.data.status == 400){
          this.$store.commit('setShowLoader', false)
          alert(res.data.message)
        }
      })
      }
    },
    check(event) {
        if (!(event.keyCode >= 48 && event.keyCode <= 57) || this.input1.length > 7) {
          event.preventDefault();
        }
    },
  },
  async created() {
    if(_.isEmpty(this.authUser)){
      await this.$store.dispatch('getUser')
    }
    if (localStorage.getItem("verification_code_time") !== null) {
      this.timeExists = true;
    }
    this.verificationSendTime = localStorage.getItem("verification_code_time") ?? '10:20:00'
    this.verficationCodeLimit = localStorage.getItem("verification_code_limit");
    this.verificationCodeTime(this.verificationSendTime);
  },
};
</script>

<style scoped>

.error{
  color: red;
}

.input-button-w{
      height: 50px;
    width: 37%;
}

.arrow-letf-head {
  color: rgb(135, 152, 173);
  width: 35px;
  height: 35px;
  padding: 9px 9px;
  border: solid 1px #e0e0e0;
  background-color: var(--white);
  border-radius: 50%;
}
.login {
  background-color: #f5f5fb;
}
.img-verify {
  text-align-last: center;
}
.field {
  width: 75px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: var(--white);
  color: #888888;
}
.field-check {
  border-radius: 3px;
  background-color: #189ab5;
}
.forgot-password {
  float: right;
  font-weight: bold;
  color: #189ab5;
}
.pad-check {
  padding-left: 40px;
  padding-right: 20px;
}
.btn-login {
  padding: 5px 50px 5px;
  border-radius: 7px;
  background-color: #3e4452;
}
.span-login {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: var(--white);
}
.btn-align {
  text-align: center;
}
.solent-email-button {
  font-family: "Montserrat", sans-serif !important;
  height: 51px;
  padding: 13px 59px 16px;
  border-radius: 7px;
  background-color: #3e4452;
  color: white;
}

@media (max-width: 768px){
  .input-button-w{
      height: 50px;
    width: 61%;
}
}
</style>